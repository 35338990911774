import React from 'react';
import styles from './Main.module.scss'
import DocumentList from "../../Components/DocumentList/DocumentList";
import Features from "../../blocks/Features/Features";
import CallBack from "../../Components/CallBack/CallBack";
import { useState } from "react";
import PopupWithForm from '../../Components/PopupWithForm/PopupWithForm';
import HelmetComponent from "../../Components/Helmet/Helmet";
import Guide from '../../blocks/Guide/Guide';
import MainIntro from '../../blocks/MainIntro/MainIntro';
import MainAbout from '../../blocks/MainAbout/MainAbout';
import up from '../../img/guide-up.svg';
import down from '../../img/guide-down.svg';
import bg from '../../img/bg__callback.svg';
import bgFeatureTop from '../../img/bgFeatureTop.svg';
import bgBottom from '../../img/bgBottom.svg';




function Main() {

    const [isFormPopupOpened, setIsFormPopupOpened] = useState(false)

    function handleFormClick() {
        setIsFormPopupOpened(true);
    }

    function closePopup() {
        setIsFormPopupOpened(false);      
    }

    return (
        <>
            <HelmetComponent
                title={'DocShablon'}
                description='DocShablon'
            />
            <MainIntro />
            <MainAbout />
                <img className={styles.main__up} src={up}  alt='sfera'/>
            <Guide />
                <img className={styles.main__down} src={down}  alt='sfera'/>
            <DocumentList
                category={'11'}
                title={'Популярные'}
                link={'/library'}
            />
            <img className={styles.main__bgTop} src={bgFeatureTop}  alt='sfera'/>
            <Features/>
            <img className={styles.main__bgBottom} src={bgBottom}  alt='sfera'/>
            <DocumentList
                category={'12'}
                title={'Новые'}
                link={'/library'}
            />
            {/*<img className={styles.main__sova} src={sova} alt=""/>*/}
            <img className={styles.main__bg} src={bg} alt=""/>
            <CallBack onButtonClick={handleFormClick}/>
            <PopupWithForm isOpen={isFormPopupOpened} onCloseClick={closePopup}/>
        </>
    );
}

export default Main;