import React from 'react';
import styles from './Document.module.scss';
import axios from 'axios';
import {useDispatch} from 'react-redux';
import {pdfGet} from '../../Redux/slices/PdfDocumentSlice';
import {useNavigate} from 'react-router-dom';

function Document({name, id, type, image, date}) {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleDownloadClick = async () => {
        try {
            const response = await axios.get(`https://api.docshablon.com/documents/${id}/download/`, {
                referrerPolicy: "unsafe_url",
                responseType: 'blob',
            });

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${name}.${type}`);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        } catch (error) {
            console.error(error);
        }
    };

    const previewPdf = async () => {
        try {
            const response = await axios.get(`https://api.docshablon.com/documents/${id}`, {

            });

            const pdfData = response.data; // извлекаем данные из ответа сервера
            console.log(pdfData)
            dispatch(pdfGet(pdfData));
          
            navigate('/viewer');
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <article className={styles.document}>
            <h1 onClick={previewPdf} className={styles.document__title}>{name.slice(0, 28)}{'...'}</h1>
            <span className={styles.document__date}>Добавлен {date}</span>
            <div  onClick={previewPdf} style={{ backgroundImage: `url(${image})` }} className={styles.document__container}>
                {/*<img  className={styles.document__image} src={image} alt={`образец документа ${name}`}/>*/}
            </div>
            <div className={styles.document__buttons}>
                <button onClick={previewPdf} className={styles.document__button}>
                    Посмотреть
                </button>
                <button onClick={handleDownloadClick}
                        className={`${styles.document__button} ${styles.document__button_download}`}>
                    Скачать
                </button>
            </div>
        </article>
    );
}

export default Document;