import React, {useRef, useState} from "react";
import {Document, Page, pdfjs} from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import styles from './PDFViewer.module.scss'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './slickCustom.scss'
import {useSelector} from "react-redux";
import axios from "axios";
import './sliderCustom.css'
import Breadcrumbs2 from "../../Components/Breadcrumbs/Breadcrumbs2";

const PDFViewer = () => {

    const file = useSelector(state => state.pdf.value.pdffile);
    const download = useSelector(state => state.pdf.value);
    const [currentSlide, setCurrentSlide] = useState(1);
    const [numPages, setNumPages] = useState(null);
    const sliderRef = useRef(null);
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    const categoryName = useSelector((state) => state.category.name);

    const handleDownloadClick = async () => {
        try {
            const response = await axios.get(`https://api.docshablon.com/documents/${download.id}/download/`, {
                responseType: 'blob' // указываем, что мы ожидаем файл
            });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${download.name}.${download.type_doc}`);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        } catch (error) {
            console.error(error);
        }
    }
    const handleSlideChange = (current, next) => {
        setCurrentSlide(next + 1);
    };

    const sliderSettings = {
        beforeChange: handleSlideChange,
        infinite: false,
        focusOnSelect: true,
        speed: 500,
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,

    };

    return (
        <div className={'pdfContainer'}>
            <div className={styles.navContainer}>
                <Breadcrumbs2
                    links={[
                        { label: 'Главная', url: '/' },
                        { label: 'Библиотека шаблонов', url: '/library' },
                        { label: `${categoryName}`, url: '/category' },
                        { label: `${download.name.slice(0, 17)}...`, url: '/viewer' },
                    ]}
                    currentLocation="/viewer"
                />
            </div>            
            <Document file={file} onLoadSuccess={({numPages}) => setNumPages(numPages)}>
                <Slider {...sliderSettings} ref={sliderRef}>
                    {Array.from(new Array(numPages), (_, index) => (
                        <div className={'test'} key={`page_${index + 1}`}>
                            <Page className={'test2'} renderTextLayer={false} pageNumber={index + 1}/>
                        </div>
                    ))}
                </Slider>
            </Document>
            <div className={styles.containerArrows}>
                <span className={styles.pagesNumber}>
                    Страница {currentSlide} из {numPages}
                </span>
                <div className={styles.containerArrows__wrapper}>
                    {numPages === 1 ? <button className='downloadButton' onClick={handleDownloadClick}>Скачать</button> : (<>
                        <button className='prevButton' onClick={() => sliderRef.current.slickPrev()}>
                        </button>
                        <button className='nextButton' onClick={() => sliderRef.current.slickNext()}>
                        </button>
                        <button className='downloadButton' onClick={handleDownloadClick}>Скачать</button>
                    </>) }
                </div>
            </div>
        </div>
    );
};

export default PDFViewer;