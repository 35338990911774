import React from 'react';
import styles from './SearchPage.module.scss';
import DocumentListSearch from "../../Components/DocumentListSearch/DocumentListSearch";
import Form from '../../Components/TemplLibrary/Form/Form';
import sfera from '../../../src/img/sfera.svg';
import Breadcrumbs2 from '../../Components/Breadcrumbs/Breadcrumbs2';
import { useState } from "react";
import PopupWithForm from "../../Components/PopupWithForm/PopupWithForm";
import { useSelector } from 'react-redux';


function SearchPage(props) {

    const category = useSelector((state) => state.category.id);

    const [isFormPopupOpened, setIsFormPopupOpened] = useState(false)

    function handleFormClick() {
        setIsFormPopupOpened(true);
    }

    function closePopup() {
        setIsFormPopupOpened(false);      
    }

    return (
        <section className={styles.searchPage}>
        <div className={styles.searchPage__navContainer}>
            <Breadcrumbs2
                links={[
                { label: 'Главная', url: '/' },
                { label: 'Результаты поиска', url: '/search' },
                ]}
                currentLocation="/search"
            /> 
        </div>
        <div className={styles.searchPage__content}>
            <DocumentListSearch
            category={category}
            />
        </div>
        <img className={styles.searchPage__sfera} src={sfera}  alt='sfera'/>
        <Form onButtonClick={handleFormClick}/>
        <PopupWithForm isOpen={isFormPopupOpened} onCloseClick={closePopup}/>
        </section>    
    )
 }
export default SearchPage;