import React from "react";
import styles from './NotFound.module.scss';
import sovaIcon from '../../img/sova.svg'


function NotFound() {
    return (
        <section className={styles.notfound} >
            <div className={styles.notfound__wrapper}>
                <div>
                    <h2 className={styles.notfound__title}>Результаты поиска</h2>
                    <p className={styles.notfound__text}>К сожалению, по Вашему запросу ничего не найдено ;&#40;</p>
                    <ul className={styles.notfound__list}>
                        <li>Проверьте написание</li>
                        <li>Перефразируйте запрос</li>
                        <li>Попробуте найти документ в Библиотеке шаблонов по категории</li>
                    </ul>
                </div>
                <img className={styles.notfound__img} src={sovaIcon} alt="Сова"/>                      
            </div>
        </section>
    )
}

export default NotFound;