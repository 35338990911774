import React from 'react';
import styles from './Guide.module.scss';
import guide from '../../img/video.mp4';
import sova from '../../img/guide-sova.svg';
import ptenchik from '../../img/guide-ptenchik.svg';
import play from '../../img/play.svg';
import { useState, useRef } from 'react';
import poster from '../../img/image153.jpg';

function Guide() {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlayPause = () => {
    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleOverlayClick = () => {
    handlePlayPause(false);
  };

  return (
    <section className={styles.guide}>
      <div className={styles.guide__container}>
        <h2 className={styles.guide__title}>Гид по сайту</h2>
        <p className={styles.guide__text}>
          Наглядное пособие, демонстрирующее Вам легкость <br /> использования сервиса DocShablon
        </p>
        <img src={sova} alt="Изображение совы" className={styles.guide__sova} />
        <div className={styles.guide__content} onClick={handleOverlayClick}>
          <video
            ref={videoRef}
            src={guide}
            alt="Картинка, затем видео"
            className={styles.guide__video}
            poster={poster}
            muted
            playsInline
          />
          <button
            onClick={handlePlayPause}
            type="button"
            className={isPlaying ? styles.guide__play_invisible : styles.guide__play}></button>
        </div>
        <img src={ptenchik} alt="Изображение совы" className={styles.guide__ptenchik} />
      </div>
    </section>
  );
}

export default Guide;