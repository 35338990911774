import React, { useState, useEffect } from 'react';
import {getUsers} from "../../../utils/api/api";
import styles from './Team.module.scss';


function Team ({onCardClick}) {
    const [usersList, setUsersList] = useState([]);
  
    useEffect(() => {
      getUsers()
      .then((res) => {
          console.log(res)
          const users = res.data.slice(0, -1);
        setUsersList(users);
        console.log(users)
    })
        .catch((err) => {
          console.error(err);
      });
    }, []);

    return (
      <div className={styles.team}>
          <h3 className={styles.team__title}>Команда</h3>
          <div className={styles.team__wrapper}>
          <ul className={styles.team__list}>             
              {usersList.map((user) => (
                      <div key={user.id} onClick={() => {
                          onCardClick(user)
                      }
                      }>
                      <img className={styles.team__member_foto} src={user.image} alt={user.first_name} />
                      <p className={styles.team__member_caption}>{user.first_name} {user.last_name}</p>
                      </div>
                ))}                  
          </ul>            
          </div>
      </div>   
    );
  };
  
  export default Team;
