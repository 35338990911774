import styles from './App.module.scss'
import Main from "../../Pages/Main/Main";
import {Route, Routes} from "react-router-dom";
import Error404 from '../../blocks/Error404/Error404';
import Layout from "../Layout/Layout";
import TemplLibrary from "../TemplLibrary/TemplLibrary";
import AboutProject from "../AboutProject/AboutProject";
import SearchPage from "../../Pages/SearchPage/SearchPage";
import ScrollToTop from "../../Hook/Scroll";
import PDFViewer from "../../Pages/SimpleSlider/PDFViewer";
import CategoryPage from "../../Pages/Category/CategoryPage";
import DataPolicy from "../../blocks/DataPolicy/DataPolicy";
import CustomCursor from "../Cursor/Cursor";

function App() {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    return (
            <div className={styles.page}>
                {isMobile ? '' : <CustomCursor/>}
                <ScrollToTop />
                <Routes>
                    <Route path="/" element={<Layout/>}>
                        <Route index element={<Main/>} />
                        <Route path="/viewer" element={<PDFViewer/>}></Route>
                        <Route path="/library" element={<TemplLibrary/>} />
                        <Route path="/about-us" element={<AboutProject/>} />
                        <Route path="/search" element={<SearchPage/>} />
                        <Route path="/policy" element={<DataPolicy />} />
                        <Route path='/category' element={<CategoryPage />} />
                        <Route path="*" element={<Error404/>} />
                    </Route>
                </Routes>
            </div>
    );
}

export default App;
