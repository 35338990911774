import React, {useEffect, useState} from "react";
import styles from './Categories.module.scss';
import {Link} from 'react-router-dom'
import {getCategory} from "../../../utils/api/api";
import {useDispatch} from "react-redux";
import {getCategoryName, getCategoryReducer} from "../../../Redux/slices/CategorySlice";

function Categories({category}) {
    const dispatch = useDispatch()
    const [categories, setCategories] = useState(null)
    useEffect(() => {
        getCategory()
            .then((res) => {
                setCategories(res.data)
            })
    }, [])


    
    return (
        <ul className={styles.category}>
            {categories && categories.slice(0).reverse().map((item) => {
                return (
                    <li className={styles.category__item}>
                        <h3 className={styles.category__title}>{item.name}</h3>
                        <Link onClick={() => {
                            dispatch(getCategoryName(`${item.name}`))
                            dispatch(getCategoryReducer(`${item.id}`))
                        } } className={styles.category__link}  to={'/category'} style={{backgroundImage: `url(${item.image})`, backgroundPosition: 'center', backgroundSize: 'cover'}}>
                        </Link>
                        <p className={styles.category__text}>{item.description}</p>
                    </li>
                )
            })
            }

        </ul>
    )
}

export default Categories;