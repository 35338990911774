import React from "react";
import styles from './Form.module.scss';
import image from '../../../img/image91.svg';
import Button from "../../Button/Button";

function Form({ onButtonClick }) {
    return(
        
        <div className={styles.feedbackForm}>
            <div className={styles.feedbackForm__container}>
                <div className={styles.feedbackForm__wrapper}>
                    <h3 className={styles.feedbackForm__title}>Также Вы можете обратиться к нам за помощью</h3>
                    <p className={styles.feedbackForm__text}>
                    Наши операторы быстро обработают Ваш запрос, сформируют шаблон на основе Ваших требований и добавят его в библиотеку.
                     Вам нужно лишь заполнить простую форму заявки</p>
                    <Button className={styles.feedbackForm__button_black} type="submit" onClick={onButtonClick} >Оставить заявку</Button>
                </div>
                <img  className={styles.feedbackForm__img} src={image} alt="Изображение"/>                
            </div>            
        </div>
    )
}

export default Form;