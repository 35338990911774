import React from "react";
import PropTypes from 'prop-types';
import classNames from "classnames";
import styles from './Button.module.scss';

function Button({children, onClick, className, disabled, active, ...attrs}) {


    const classes = classNames(
        styles.btn,
        className,
        { active }
    )

    const onClickAction = evt => {
        if(disabled) {
            evt.preventDefault();
        } else {
            return onClick(evt)
        }
    }

    const Tag = attrs.href ? 'a' : 'button'

    return (
        <Tag
        { ...attrs }
        className={classes}
        disabled={disabled}
        onClick={onClickAction}
        >{children}
        </Tag>
    )
}

Button.propTypes = {
    children: PropTypes.node,
    onClick: PropTypes.func,
    classNames: PropTypes.string,
    disabled: PropTypes.bool,
    active: PropTypes.bool,
}

Button.defaultProps = {
    children: 'Default button',
    onClick: () => {},
    classNames: '',
    disabled: false, 
    active: false,
}

export default Button;