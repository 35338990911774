import React from 'react';
import styles from './BreadCrumbs.module.scss';
import {Link} from "react-router-dom";


function Breadcrumbs2(props) {
  const { links, currentLocation } = props;

  const currentIndex = links.findIndex(link => link.url === currentLocation);

  const breadcrumbLinks = links.slice(0, currentIndex + 1);

  return (
    <nav aria-label="breadcrumb">
      <ul className={styles.links}>
        {breadcrumbLinks.map((link, index) => (
          <li key={index}  className={`${styles.links__main} ${index === breadcrumbLinks.length - 1 ? styles.links__text : styles.links__main}`}>
          {index === breadcrumbLinks.length - 1 ? link.label : <Link className={styles.links__main}  to={link.url}>{link.label}</Link>}
        </li>
        ))}
      </ul>
    </nav>
  );
}

export default Breadcrumbs2;
