import React from "react";
import styles from './Error404.module.scss';
import { NavLink } from 'react-router-dom';
import errorImage from '../../img/error404.svg';
import Button from "../../Components/Button/Button";
 
function Error404() {
    return (
        <section className={styles.error}>
            <div className={styles.error__container}>
                <h3 className={styles.error__title}>Упс! Что-то пошло не так...</h3>
                <p className={styles.error__text}>Иногда совы не то, чем кажутся. Вернитесь, пожалуйста, на главную страницу</p>
                <NavLink to="/">
                    <Button className={styles.error__button_red}>На главную</Button> 
                </NavLink>
            </div>
            <img className={styles.error__img} src={errorImage} alt="Ошибка 404" />
        </section>
    )
}

export default Error404