import axios from "axios";

export function fetchDocuments(value) {
    return axios.get(`https://api.docshablon.com/documents/?search=${value}`)
        .then((res) => res.data.results)
        .catch((err) => {
            throw new Error(err.message);
        });
}

export function getCategory() {
    return axios.get(`https://api.docshablon.com/categories/`)
        .then((res) => {
            return res
        })
        .catch((err) => {
            throw new Error(err.message);
        });
}

export function getUsers() {
    return axios.get(`https://api.docshablon.com/users/?ordering=id`)
        .then((res) => {
            return res
        })
        .catch((err) => {
            throw new Error(err.message);
        });
}
