import React, {useState} from 'react';
import styles from "./PopupWithForm.module.scss";
import {Link} from "react-router-dom";
import ReCAPTCHA from 'react-google-recaptcha';
import Button from "../Button/Button";


function FormOnly({ok}) {

    const [verified, setVerified] = useState(false);

    function onChange(value) {
    console.log('Captcha value:', value);
    setVerified(true);
    }

    const [isSuccess, setIsSuccess] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [inputValue1, setInputValue1] = useState("");
    const [inputValue2, setInputValue2] = useState("");
    const [textValue, setTextValue] = useState("");

    function handleSubmit(evt) {
        evt.preventDefault();
        const formData = new FormData(evt.target);
        formData.set("is_agreed", isChecked);
        formData.set("description", textValue);
        formData.set("name", inputValue1);
        formData.set("email", inputValue2);
        fetch("https://api.docshablon.com/offers/", {
            method: 'POST',
            body: formData
        })
            .then(response => response.json())
            .then(data => {
                ok()
                setInputValue1('')
                setInputValue2('')
                setTextValue('')
                setIsChecked(false)

            })

            .catch(error => {
                console.error(error);
            });

    }

    function changeCheckbox(evt) {
        setIsChecked(evt.currentTarget.checked);
        console.log('1');
    }

    return (
        <form id='form' className={styles.formPopup__wrapper} onSubmit={handleSubmit}>
            <h3 className={styles.formPopup__title}>Заявка на добавление нового шаблона</h3>
            <textarea className={styles.formPopup__text}
                      required
                      cols="30"
                      rows="4"
                      value={textValue} onChange={e => setTextValue(e.target.value)}
                      placeholder="Напишите здесь, какой шаблон Вам требуется? С какой проблемой Вы сталкиваетесь в процессе подготовки документа? Так мы сможем помочь Вам быстрее.">
            </textarea>
            <input className={styles.formPopup__input} required type="text" placeholder="Ваше Имя"
                   value={inputValue1} onChange={e => setInputValue1(e.target.value)}/>
            <input className={styles.formPopup__input} required type="email" placeholder="E-mail"
                   value={inputValue2} onChange={e => setInputValue2(e.target.value)}/>
            <div className={styles.formPopup__container_check}>
                <input className={styles.formPopup__checkbox} type="checkbox" onChange={changeCheckbox}
                checked={isChecked}/>
                <p className={styles.formPopup__link}> Я согласен с <Link
                    className={styles.formPopup__link_accent} target="_blank" to='/policy'>политикой обработки
                    данных</Link></p>
            </div>
            <div className={styles.formPopup__captcha}>
              <ReCAPTCHA sitekey="6LcsWkkmAAAAABim39g2PVVdo1_HlSuZKQuYzKX0" onChange={onChange} />
            </div>
            <Button 
            disabled={!isChecked || !verified}
            className={styles.formPopup__button_white} 
            type="submit"
            onClick={() => console.log(textValue)}> Оставить заявку</Button>
        </form>
    );
}

export default FormOnly;

