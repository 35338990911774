import React, {useEffect, useState} from "react";
import styles from './Cursor.module.scss'

const CustomCursor = () => {
    const [position, setPosition] = useState({x: 0, y: 0});
    const [hovered, setHovered] = useState(false);

    useEffect(() => {
        const onMouseMove = (e) => {
            setPosition({x: e.clientX, y: e.clientY});
        };

        document.body.addEventListener("mousemove", onMouseMove);

        return () => {
            document.body.removeEventListener("mousemove", onMouseMove);
        };
    }, []);

    useEffect(() => {
        const onHover = (e) => {
            setHovered(true);
        };

        const onLeave = (e) => {
            setHovered(false);
        };

        const buttons = document.querySelectorAll("button");
        const a = document.querySelectorAll("a");

        a.forEach((link) => {
            link.addEventListener("mouseenter", onHover);
            link.addEventListener("mouseleave", onLeave);
        });

        buttons.forEach((button) => {
            button.addEventListener("mouseenter", onHover);
            button.addEventListener("mouseleave", onLeave);
        });

        return () => {
            buttons.forEach((button) => {
                button.removeEventListener("mouseenter", onHover);
                button.removeEventListener("mouseleave", onLeave);
            });
            a.forEach((link) => {
                link.removeEventListener("mouseenter", onHover);
                link.removeEventListener("mouseleave", onLeave);
            });
        };
    }, []);

    const cursorStyle = {
        transform: `translate3d(${position.x}px, ${position.y}px, 0)`
    };

    const cursorClasses = `${styles.cursor} ${hovered ? styles.hovered : ""}`;

    return (
        <>
            <div className={cursorClasses} style={cursorStyle}></div>
            <style>{`
                    body {
          cursor: none;
        }
        button {
          cursor: none !important;
        }
        a {
         cursor: none !important;
        }

      `}
            </style>
        </>
    );
};

export default CustomCursor;
