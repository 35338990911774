import { configureStore } from '@reduxjs/toolkit';
import searchReducer from '././slices/SearchInputSlice';
import pdfDocumentSlice from "./slices/PdfDocumentSlice";
import categorySlice from "./slices/CategorySlice";

const store = configureStore({
    reducer: {
        search: searchReducer,
        pdf: pdfDocumentSlice,
        category: categorySlice
    }
});

export default store;