import React from "react";
import styles from './TemplLibrary.module.scss';
import Nav from "../Nav/Nav";
import Categories from "./Categories/Categories";
import FeedbackForm from "./FeedbackForm/FeedbackForm";
import HelmetComponent from "../Helmet/Helmet";
import sfera from '../../../src/img/sfera.svg';
import Breadcrumbs2 from "../Breadcrumbs/Breadcrumbs2";
import { useState } from "react";
import PopupWithForm from "../PopupWithForm/PopupWithForm";


function TemplLibrary() {

    const [isFormPopupOpened, setIsFormPopupOpened] = useState(false)

    function handleFormClick() {
        setIsFormPopupOpened(true);
    }

    function closePopup() {
        setIsFormPopupOpened(false);      
    }

    
    return (
        <>
            <HelmetComponent
                title='Библиотека шаблонов'
                description='Библиотека шаблонов'
            />
            <section className={styles.templateLib}>
                <Nav>
                   <Breadcrumbs2
                        links={[
                            { label: 'Главная', url: '/' },
                            { label: 'Библиотека шаблонов', url: '/library' },
                        ]}
                        currentLocation="/library"
                    />
                </Nav>
                <div className={styles.templateLib__categories}>
                    <h2 className={styles.templateLib__title}>Выберите категорию</h2>
                    <Categories/>
                </div>
                 <img src={sfera} className={styles.templateLib__sfera}  alt='sfera'/>
                <FeedbackForm onButtonClick={handleFormClick} to="/popup"/>
                <PopupWithForm isOpen={isFormPopupOpened} onCloseClick={closePopup}/>
            </section>
        </>
    )
}


export default TemplLibrary;