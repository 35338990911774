import React from "react";
import styles from './Intro.module.scss';
import image from '../../../img/sova-intro.svg'
import { Link } from "react-router-dom";
import Button from "../../Button/Button";
import SearchInput from '../../SearchInput/SearchInput';

function Intro(){
    return (
        <div className={styles.intro}>
            <div className={styles.intro__wrapper}>
                <div className={styles.intro__container_text}>                    
                    <h1 className={styles.intro__title}>О проекте</h1>
                    <p className={styles.intro__text}>Простая и понятная база шаблонов документов на все случаи жизни</p>                                       
                    <div className={styles.intro__container}>          
                    <Link to="/library">
                        <Button className={styles.intro__button_red}>Библиотека шаблонов</Button> 
                    </Link>
                    <SearchInput />                    
                    </div> 
                </div>
                <img src={image} alt='Сова' className={styles.intro__image} />                                      
            </div>
        </div>
    )
}

export default Intro;