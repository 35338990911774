import React from 'react';
import styles from './CategoryPage.module.scss';
import DocumentList from "../../Components/DocumentList/DocumentList";
import { useSelector } from 'react-redux';
import FeedbackForm from '../../Components/TemplLibrary/FeedbackForm/FeedbackForm';
import PopupWithForm from '../../Components/PopupWithForm/PopupWithForm';
import { useState } from 'react';
import sfera from '../../img/sfera.svg';
import Breadcrumbs2 from '../../Components/Breadcrumbs/Breadcrumbs2';


function CategoryPage() {
    const categoryName = useSelector((state) => state.category.name);
    const category = useSelector((state) => state.category.id);
    console.log(categoryName)

    const [isFormPopupOpened, setIsFormPopupOpened] = useState(false)

    function handleFormClick() {
        setIsFormPopupOpened(true);
    }

    function closePopup() {
        setIsFormPopupOpened(false);      
    }


    return (        
        <div>
            <div className={styles.catPage__navContainer}>
            <Breadcrumbs2
                links={[
                    { label: 'Главная', url: '/' },
                    { label: 'Библиотека шаблонов', url: '/library' },
                    { label: `${categoryName}`, url: '/category' },
                ]}
                currentLocation="/category"
            />
            </div>            
            <h3 className={styles.catPage__title}>{categoryName}</h3>
            <DocumentList
                link={'/library'}
                category={category}
            />
            <img className={styles.catPage__sfera} src={sfera}  alt='sfera'/>  
            <FeedbackForm onButtonClick={handleFormClick} />
            <PopupWithForm isOpen={isFormPopupOpened} onCloseClick={closePopup}/>
        </div>
    );
}

export default CategoryPage;