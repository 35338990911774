import React, { useEffect, useState } from 'react';
import Document from '../Document/Document';
import styles from '../DocumentList/DocumentList.module.scss';
import { useSelector } from 'react-redux';
import NotFound from '../NotFound/NotFound';
import Loading from '../Loading/Loading';
import { fetchDocuments } from '../../utils/api/api';
import axios from "axios";
import ReactPaginate from 'react-paginate';
import previous from '../../img/previous.svg';
import next from '../../img/next.svg';

function DocumentListSearch({category}) {
    //пагинация
    const [nextPage, setNextPage] = useState('')
    const [countPage, setCountPage] = useState(1)
    const [currentPage, setCurrentPage] = useState(1);


    const [documents, setDocuments] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const value = useSelector((state) => state.search.value);

    useEffect(() => {
        setIsLoading(true);
        fetchDocuments(value)
            .then((results) => {
                setDocuments(results);
                setIsLoading(false);
            })
            .catch((err) => {
                setError(err.message);
                setIsLoading(false);
            });
    }, [value]);

    //пагинация
    function handlePageClick(data) {
        setCurrentPage(data.selected + 1); // устанавливаем текущую страницу при клике на пагинацию
    }

    useEffect(() => {
        setIsLoading(true);
        const data = localStorage.getItem(category);
        if (data) {
            fetchData(currentPage)
        } else {
            fetchData(currentPage)
        }
    }, [currentPage])


    function fetchData(page) { // обновленная функция fetchData, принимает номер страницы
        axios
            .get(`https://api.docshablon.com/documents/&page=${page}`) // добавление параметра страницы в URL запроса
            .then((res) => {
                setCountPage(res.data.count / 16)
                setDocuments(res.data.results);
                setNextPage(res.data.next)

                const data = {
                    category: res.data.results,
                    date: new Date().toISOString(),
                };
                const jsonData = JSON.stringify(data);
                localStorage.setItem(category, jsonData);
                setIsLoading(false);
            })
            .catch((err) => {
                setError(err.message);
                setIsLoading(false);
            });
    }
      

    return (
        <section className={styles.documentList}>
            {isLoading ? (
                <Loading />
            ) : documents.length >= 1 ? (
                <>
                    <h2 className={styles.documentList__title}>Результаты поиска</h2>
                    <ul className={styles.documentList__list}>
                        {documents.map((item) => {
                            return (
                                <Document
                                    image={item.screenshot}
                                    type={item.type_doc}
                                    name={item.name}
                                    key={item.id}
                                    id={item.id}
                                />
                            );
                        })}
                    </ul>
                    { countPage > 1 &&
                        <ReactPaginate
                        pageCount={countPage} // общее количество страниц
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={handlePageClick}
                        containerClassName={styles.pagination}
                        activeClassName={styles.pagination__active}
                        previousClassName={currentPage === 1 ? styles.pagination__previousDisabled : ""}
                        previousLabel={<img src={previous} alt="Назад" />} 
                        nextLabel={<img src={next} alt="Вперед" />}
                        />
                     }
                </>
            ) : (
                <NotFound />
            )}
        </section>
    );
}


export default DocumentListSearch;