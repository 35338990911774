import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import React, { useState } from "react";
import { changeValue } from "../../Redux/slices/SearchInputSlice";
import styles from './SearchInput.module.scss'

const SearchInput = ({ onCloseClick }) => {
    const dispatch = useDispatch();
    const value = useSelector(state => state.search.value);
    const location = useLocation()
    const navigate = useNavigate();
    const [val, setVal] = useState('');
    const [formError, setFormError] = useState('');

    const handleChange = (e) => {
        setVal(e.target.value);
        setFormError('');
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (val.trim().length < 2) {
            setFormError('Введите минимум два символа');
        } else {
            dispatch(changeValue(val));
            navigate('/search');
        }
    };

    const handleCloseClick = () => {
        setVal('');
        setFormError('');
        dispatch(changeValue(''));
        document.activeElement.blur();
        onCloseClick();
    };

    return (
        <form onSubmit={handleSubmit} className={styles.search_form}>
            {formError && <span className={styles.search__inputError}>{formError}</span>}
            <input
                onClick={() => {
                    setVal('');
                    setFormError('');
                    dispatch(changeValue(''));
                }}
                value={val}
                onChange={handleChange}
                className={
                    location.pathname === "/" || location.pathname === "/about-us" ? styles.search__input : styles.search__input_modif
                }
                placeholder="Что Вы ищете?"
                type="search"
            />
            <button
                type="button"
                onClick={handleCloseClick}
                className={!formError ? styles.search_button : `${styles.search_button} ${styles.search_button_error}`}
            ></button>
        </form>
    );
}

export default SearchInput;