import { createSlice } from '@reduxjs/toolkit';

const CategorySlice = createSlice({
    name: 'category',
    initialState: {
        name: '',
        id: {},

    },
    reducers: {
        getCategoryReducer: (state, action) => {
            state.id = action.payload; // установка введенного значения в состояние
        },
        getCategoryName: (state, action) => {
            state.name = action.payload; // установка введенного значения в состояние
        }
    }
});

export const { getCategoryReducer, getCategoryName } = CategorySlice.actions;

export default CategorySlice.reducer;