import { createSlice } from '@reduxjs/toolkit';

const searchInputSlice = createSlice({
    name: 'search',
    initialState: {
        value: 'Что вы ищите?',
    },
    reducers: {
        changeValue: (state, action) => {
            state.value = action.payload; // установка введенного значения в состояние
        },

    }
});

export const { changeValue } = searchInputSlice.actions;

export default searchInputSlice.reducer;