import React, {useEffect} from "react";
import styles from './AboutProject.module.scss';
import AboutUs from "./AboutUs/AboutUs";
import Intro from "./Intro/Intro";
import Team from "./Team/Team";
import { useState } from "react";
import sfera from '../../../src/img/sfera-reverse.svg';
import sfere from '../../img/sfere2.svg';
import HelmetComponent from "../Helmet/Helmet";
import PhotoPopup from "./PhotoPopup/PhotoPopup";
import {getUsers} from "../../utils/api/api";


function AboutProject() {

    const [isPhotoPopupOpened, setIsPhotoPopupOpened] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);



    function handlePhotoClick(user) {
        setIsPhotoPopupOpened(true);

        setSelectedUser(user);
    }

    function closePopup() {
        setIsPhotoPopupOpened(false);
        setSelectedUser(null);
    }

    return (
        <>
                <HelmetComponent
                    title='О проекте'
                    description='О проекте'
                    />
                <section className={styles.aboutProject}>
                    <Intro />
                    <img className={styles.aboutProject__sfera} src={sfera}  alt='sfera'/>
                    <AboutUs />
                    <img className={styles.aboutProject__sfere} src={sfere}  alt='sfera'/>
                    <Team onCardClick={handlePhotoClick}/>
                    <div className={styles.aboutProject__container}>
                    </div>
                    {selectedUser && <PhotoPopup user={selectedUser} isOpen={isPhotoPopupOpened} onCloseClick={closePopup}/>}
                </section>
        </>
        
    )
}

export default AboutProject;