import React, {useEffect, useState} from 'react';
import Document from "../Document/Document";
import styles from "./DocumentList.module.scss"
import ButtonMore from "../ButtonMore/ButtonMore";
import axios from "axios";
import {useLocation} from "react-router-dom";
import ReactPaginate from 'react-paginate';
import previous from '../../img/previous.svg';
import next from '../../img/next.svg';
function DocumentList({category, link, title, filter}) {
    const [nextPage, setNextPage] = useState('')
    const [countPage, setCountPage] = useState(1)
    const location = useLocation()
    const [currentPage, setCurrentPage] = useState(1);
    const [documents, setDocuments] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');



    function handlePageClick(data) {
        setCurrentPage(data.selected + 1); // устанавливаем текущую страницу при клике на пагинацию
    }

 

    useEffect(() => {
        setIsLoading(true);
        const data = localStorage.getItem(category);
        if (data) {
            // const {category: storedCategory, date} = JSON.parse(data);
            // setDocuments(storedCategory)
            // const storedDate = new Date(date);
            // const currentDate = new Date();
            // if (currentDate.getTime() - storedDate.getTime() > 30 * 60 * 1000) {
            //     // Если прошло более 30 минут, удаляем данные из localStorage и загружаем их заново
            //     localStorage.removeItem(category);
            //     // fetchData();
            // }
            fetchData(currentPage)
        } else {
            fetchData(currentPage)
        }
    }, [currentPage])

    function fetchData(page) { // обновленная функция fetchData, принимает номер страницы
        axios
            .get(`https://api.docshablon.com/documents/?category=${category}&page=${page}`) // добавление параметра страницы в URL запроса
            .then((res) => {
                setCountPage(res.data.count / 16)
                setDocuments(res.data.results);
                setNextPage(res.data.next)

                const data = {
                    category: res.data.results,
                    date: new Date().toISOString(),
                };
                const jsonData = JSON.stringify(data);
                localStorage.setItem(category, jsonData);
                setIsLoading(false);
            })
            .catch((err) => {
                setError(err.message);
                setIsLoading(false);
            });
    }

    return (
        <section className={styles.documentList}>
            <h2 className={styles.documentList__title}>{title}</h2>
            <ul className={styles.documentList__list}>
                {
                    documents.map((item) => {
                        return <Document
                            image={item.screenshot}
                            type={item.type_doc}
                            name={item.name}
                            key={item.id}
                            id={item.id}
                            date={item.add_time}
                        />
                    })}

            </ul>
            {location.pathname === '/category' ? '' : <ButtonMore
                link={link}
            />}
            { countPage > 1 &&
                <ReactPaginate
                    pageCount={countPage} // общее количество страниц
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageClick}
                    containerClassName={styles.pagination}
                    activeClassName={styles.pagination__active}
                    previousClassName={currentPage === 1 ? styles.pagination__previousDisabled : ""}
                    previousLabel={<img src={previous} alt="Назад" />} 
                    nextLabel={<img src={next} alt="Вперед" />}
                />
            }
        </section>

    );
}


export default DocumentList;

