import React from 'react';
import styles from './MainIntro.module.scss';
import SearchInput from '../../Components/SearchInput/SearchInput';
import { Link } from 'react-router-dom';
import picture from '../../img/main_picture.svg';
import bg from '../../img/bg_main.svg'

function MainIntro() {
    return (
        <section className={styles.main}>
                <img src={bg} className={styles.main__bg} alt=""/>
                <div className={styles.main__layout}>
                    <div className={styles.main__container}>
                        <h1 className={styles.main__title}>DocShablon</h1>
                        <p className={styles.main__about}>Простая и понятная база шаблонов документов на все случаи
                            жизни</p>
                        <div className={styles.main__shablon}>
                            <Link className={styles.main__button} to={'/library'}>Библиотека шаблонов</Link>
                            <SearchInput/>
                        </div>
                    </div>
                    <img className={styles.main__image} src={picture} alt=""/>
                </div>
        </section>
    )
}

export default MainIntro;
