import React from 'react';
import {Link} from "react-router-dom";
import styles from './ButtonMore.module.scss'
function ButtonMore({link}) {
    return (
        <Link className={styles.button__position} to={link}>
                <span className={styles.button__more}>Библиотека шаблонов</span>
        </Link>
    );
}

export default ButtonMore;