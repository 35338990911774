import React from 'react';
import styles from './Features.module.scss'
import featureImage from '../../img/featuresImage.svg'
import stepOne from '../../img/step_1.svg'
import stepTwo from '../../img/step_2.svg'
import stepThree from '../../img/step_3.svg'
import stepFour from '../../img/step_4.svg'


function Features(props) {
    return (
        <>
            <section className={styles.features}>
                <div className={styles.layout}>
                    <div className={styles.features__index}>
                        <h2 className={styles.features__title}>Преимущества</h2>
                        <ul className={styles.features__list}>
                            <li className={styles.features__listItem}>
                                <img className={styles.features__listItemImage} src={stepOne} alt=""/>
                                <div>
                                    <p className={styles.features__listItemTitle}>Экономьте время на рутинных задачах,
                                        освобождайте пространство для креативных идей </p>
                                    <p className={styles.features__listItemText}>Пользуйтесь готовыми шаблонами документов,
                                        редактируйте и сохраняйте в удобных для Вас форматах: doc, pdf, xls</p>
                                </div>
                            </li>
                            <li className={styles.features__listItem}>
                                <img className={styles.features__listItemImage} src={stepTwo} alt=""/>
                                <div>
                                    <p className={styles.features__listItemTitle}>Сократите ошибки</p>
                                    <p className={styles.features__listItemText}>Шаблоны содержат основную информацию, Вам
                                        нужно лишь добавить детали</p>
                                </div>
                            </li>
                            <li className={styles.features__listItem}>
                                <img className={styles.features__listItemImage}  src={stepThree} alt=""/>
                                <div>
                                    <p className={styles.features__listItemTitle}>Упростите работу</p>
                                    <p className={styles.features__listItemText}>В DocShablon нет сложных и запутанных баз
                                        данных: ищите шаблоны по ключевым словам или в библиотеке шаблонов</p>
                                </div>
                            </li>
                            <li  className={styles.features__listItem}>
                                <img className={styles.features__listItemImage} src={stepFour} alt=""/>
                                <div>
                                    <p className={styles.features__listItemTitle}>Оптимизируйте расходы</p>
                                    <p className={styles.features__listItemText}>В DocShablon нет дорогих подписок:
                                        пользуйтесь более чем 350 бесплатными шаблонами, или по Вашей заявке оператор
                                        добавит в базу документов нужный Вам шаблон</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <img className={styles.features__images} src={featureImage} alt=""/>
                </div>
            </section>
        </>

    );
}

export default Features;