import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useState, useRef } from "react";
import { changeValue } from "../../Redux/slices/SearchInputSlice";
import styles from './SearchInputModif.module.scss'

const SearchInputModif = ({ onSubmit }) => {
    const dispatch = useDispatch();
    const value = useSelector(state => state.search.value);
    const navigate = useNavigate();
    const [val, setVal] = useState('');
    const [formError, setFormError] = useState('');
    const [isOpened, setIsOpened] = useState(false);
    const inputRef = useRef(null);

    useEffect(() => {
        if (isOpened) {
            inputRef.current.focus();
        }
    }, [isOpened]);

    const handleCloseSearch = () => {
        setIsOpened(false);
    }

    const handleChange = (e) => {
        setVal(e.target.value);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        handleCloseSearch();
        if (val.trim().length < 2) {
            // Дополнительная логика, если значение не прошло проверку
        } else {
            dispatch(changeValue(val));
            navigate('/search');
            onSubmit(); // Вызываем функцию onSubmit из компонента Header
        }
    }

    return (
        <form onSubmit={handleSubmit} className={styles.search__form}>
            {formError && <span className={styles.search__input_modifError}>{formError}</span>}
            <input
                ref={inputRef}
                onClick={() => {
                    setVal('');
                    setFormError('');
                    dispatch(changeValue(''))
                }}
                value={val}
                onChange={handleChange}
                className={styles.search__input_modif}
                placeholder='Что Вы ищете?'
                type="search"
            />
            <button type='submit' className={!formError ? styles.search_button : `${styles.search_button} ${styles.search_button_error}`}></button>
        </form>
    );
}

export default SearchInputModif;
