import React from 'react';
import styles from './MainAbout.module.scss';
import { Link } from 'react-router-dom';
import aboutImage from '../../img/aboutimage.svg';


function MainAbout() {
    return (
        <section className={styles.about}>
                <div className={styles.about__layout}>
                    <img className={styles.about__image} src={aboutImage} alt="О проекте DocShablon"/>
                    <div className={styles.about__wrapper}>
                        <h2 className={styles.about__title}>О проекте</h2>
                        <p className={styles.about__text}>DocShablon – это бесплатный инструмент для поиска и
                            редактирования шаблонов документов прямо на сайте с возможностью скачивания документов в
                            удобных для Вас форматах.</p>
                        <p className={styles.about__text}>Каждый день в мире генерируется квинтиллионы байт цифровой
                            информации, и по меньшей мере половина – это точно стандартные, скучные, повторяющиеся
                            тексты: бесконечные заявления, акты выполненных работ, гарантийные письма и доверенности.
                        </p>
                        <p className={styles.about__text}>DocShablon позволит Вам сконцентрироваться на действительно
                            важном, и даже выпить еще одну чашечку капучино. А рутинную работу выполнит DocShablon.</p>
                        <Link to='/about-us' className={styles.about__link}>Подробнее</Link>
                    </div>
                </div>
            </section>
    )
}

export default MainAbout;