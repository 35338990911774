import React, {useState} from "react";
import styles from "./PopupWithForm.module.scss";
import {Link} from "react-router-dom";
import Button from "../Button/Button";
import FormOnly from "./FormOnly";

function PopupWithForm({isOpen, onCloseClick}) {
    const [ok, setOk] = useState(false);

    function handleSuccess() {
        setOk(true);
    }

    function handleClose() {
        setOk(false);
        onCloseClick();
    }

    return (
        <section className={isOpen ? styles.formPopup__opened : styles.formPopup}>
            <div className={styles.formPopup__content}>
                <button
                    type="button"
                    className={styles.formPopup__close}
                    onClick={handleClose}
                ></button>
                {ok ? (
                    <div className={styles.success}>
                        <p className={styles.success__title}>Спасибо!</p>
                        <p className={styles.success__text}>Наша сова уже взялась за дело! Как только шаблон будет добавлен,
                            мы Вас проинформируем. </p>
                        <p className={styles.success__text}>Warning: никакой совиной почты, только указанный Вами способ
                            связи</p>
                    </div>
                ) : (
                    <FormOnly ok={handleSuccess}/>
                )}
            </div>
        </section>
    );
}

export default PopupWithForm;