import React from "react";
import styles from './Nav.module.scss';
import SearchInput from "../SearchInput/SearchInput";

function Nav({children}){

    
    return (
        <div className={styles.nav}>
            <div className={styles.nav__links}>
            {children}          
            </div>
            <SearchInput />
        </div>
    )
}

export default Nav;