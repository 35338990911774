import React from "react";
import styles from './FeedbackForm.module.scss';
import image from '../../../img/image97.png'
import Button from "../../Button/Button";

function FeedbackForm({ onButtonClick }) {
    return(
        
        <div className={styles.feedbackForm}>
            <div className={styles.feedbackForm__container}>
                <div className={styles.feedbackForm__wrapper}>
                    <h3 className={styles.feedbackForm__title}>Не нашли то, что искали? Мы можем Вам помочь!</h3>
                    <p className={styles.feedbackForm__text}>
                        Отправьте заявку, чтобы наши операторы смогли добавить нужный Вам шаблон в базу документов.
                        Мы проинформируем Вас о проделанной работе удобным для Вас способом</p>
                    <Button className={styles.feedbackForm__button_black} type="submit" onClick={onButtonClick} >Оставить заявку</Button>
                </div>
                <img  className={styles.feedbackForm__img} src={image} alt="Изображение"/>                
            </div>
        </div>
    )
}

export default FeedbackForm;