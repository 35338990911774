import React from 'react';
import styles from './CallBack.module.scss'
import mainPicture from '../../img/img.svg'
import Button from '../Button/Button';
import sova from '../../img/polovinka.svg';

function CallBack({onButtonClick}) {
    return (
        <section className={styles.callback}>
            <img className={styles.layout__sova} src={sova} alt=""/>
            <div className={styles.layout}>
                <div className={styles.layout__more}>
                    <div className={styles.callback__container}>
                        <h2 className={styles.callback__title}>А что делать, если на сайте нет нужного шаблона?</h2>
                        <p className={styles.callback__text}>Просто оставьте заявку нашим операторам! И мы оперативно
                            добавим требуемый Вам шаблон в библиотеку документов </p>                    
                        <Button className={styles.callback__button} type="submit" onClick={onButtonClick} >Оставить заявку</Button>
                    </div>
                    <img className={styles.callback__picture} src={mainPicture} alt=""/>
                </div>
            </div>
            
        </section>
    );
}

export default CallBack;