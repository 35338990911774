import React from 'react';
import styles from './Footer.module.scss'
import {Link} from "react-router-dom";
import logo from '../../img/logo.svg'
import telegram from '../../img/telegram.svg'
import vk from '../../img/vk.svg'
function Footer(props) {
    return (
        <footer className={styles.footer}>
            <div className={styles.footer__container}>
                <ul className={styles.footer__links}>
                    <Link className={styles.footer__link} to={'/about-us'}>О проекте</Link>
                    <Link className={styles.footer__link} to={'/library'}>Библиотека шаблонов</Link>
                </ul>
                <div className={styles.footer__space}>
                    <div className={styles.footer__logoContainer}>
                        <Link className={styles.footer__click} to={'/'}><img src={logo} alt=""/></Link>
                        <Link className={styles.footer__click} to={'/'}><p className={styles.footer__textLogo}>DocShablon</p></Link>
                    </div>
                    <div className={styles.footer__social}>
                        <a href='https://t.me/docshabloncom'>
                            <img src={telegram} alt="" />
                        </a>           
                        <a href='https://vk.com/club220157071'>
                            <img src={vk} alt="" />
                        </a>                    
                    </div>
                </div>
                <div className={styles.footer__contact}>
                    <p className={styles.footer__contacttext}>Контакты</p>
                    <a href="mailto:docshablon@yandex.ru" className={styles.footer__email}>docshablon@yandex.ru</a>
                    <a href='tel:+79161667500' className={styles.footer__phone}>+7 (916) 166-75-00</a>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
