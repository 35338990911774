import React from "react";
import styles from './AboutUs.module.scss';
//import { NavLink } from 'react-router-dom';
import imagePencil from '../../../img/pencil.svg'
import Breadcrumbs2 from "../../Breadcrumbs/Breadcrumbs2";

function AboutUs() {
    return(
        <div className={styles.aboutUs}>
            <div className={styles.aboutUs__content}>
                <div className={styles.aboutUs__links}>
                    <Breadcrumbs2
                        links={[
                            { label: 'Главная', url: '/' },
                            { label: 'О проекте', url: '/about-us' },
                        ]}
                        currentLocation="/about-us"
                    />    
                </div>
                <div className={styles.aboutUs__container}>
                    <img src={imagePencil} alt="Изображение карандаша" className={styles.aboutUs__img}/>
                    <div className={styles.aboutUs__wrapper}>
                        <h3 className={styles.aboutUs__title}>О нас</h3>
                        <p className={styles.aboutUs__text}>
                            Мы знаем, что наша жизнь изменчива, непостоянна и ее нельзя вместить в рамки чего-то готового. Но упростить ее, хотя бы наше взаимодействие с документами – точно можно. <br/>
                            <br/>
                            Поэтому мы придумали DocShablon с шаблонами документов для разных жизненных ситуаций.
                            Среди нас есть предприниматели, наемные работники и фрилансеры, студенты, просто мамы и папы. Каждый день мы сталкиваемся с необходимостью работать с документами по разным задачам. Поэтому мы точно знаем, для чего делаем DocShablon. Чтобы было быстрее и проще найти документ – без неоправданно дорогих подписок и громоздких баз.<br/>
                            <br/>
                            Мы разные, с разными компетенциями и опытом, но нас объединяет желание сделать Вашу работу с документами проще.
                        </p>
                    </div>
                </div>
            </div>            
        </div>
    )
}

export default AboutUs;