import styles from './Header.module.scss'
import React, {useEffect, useState} from 'react';
import {Link, NavLink, useLocation} from "react-router-dom";
import logo from "../../img/logo.svg"
import phoneMobile from '../../img/phoneMobile.svg'
import imageMobile from '../../img/menuimage.svg'
import SearchInputModif from '../SearchInputModif/SearchInputModif';
import owl from '../../img/search-owl.svg';

function Header() {
    const [open, setOpen] = useState(false)
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [isOpened, setIsOpened] = useState(false)
    const location = useLocation().pathname
    const  [pagePosition, setPagePosition] = useState(location)


    useEffect(() => {
        setIsOpened(false)
    }, [])
    useEffect(() => {
        function handleResize() {
            setWindowWidth(window.innerWidth);
        }

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    const closeMenu = () => {
        if (windowWidth <= 980) {
            document.body.style.overflow = !open ? 'hidden' : ''
            setOpen(!open)
        }
    }


    function handleSearchClick() {
        setIsOpened(true);
    }

    function handleCloseSearch() {
        setIsOpened(false);
    }
    function handleFormSubmit() {
        setIsOpened(false);
    }

    useEffect(() => {
        setOpen(false)
        function closeByEscape(evt) {
            if (evt.key === 'Escape') {
                handleCloseSearch();
            }
        }

        if (isOpened) {
            document.addEventListener('keydown', closeByEscape);
            return () => {
                document.removeEventListener('keydown', closeByEscape);
            }
        }
    }, [isOpened, pagePosition])

    return (
        <header className={styles.header}>
            <div className={styles.layout}>
                {windowWidth <= 980 && !isOpened
                    ?
                    <button onClick={closeMenu}
                            className={open ? `${styles.header__close} ${styles.header__close__open}` : styles.header__close}></button>
                    : ''}

                <Link to='/'>
                    <img src={logo} className={styles.header__logo} alt="Логотип сервиса DocsHablon"/>
                </Link>
                <nav className={open ? `${styles.header__nav} ${styles.header__nav_open}` : styles.header__nav}>
                    <ul className={styles.header__nav_container}>
                        <li>
                            <NavLink
                                onClick={closeMenu}
                                className={({isActive}) =>
                                    isActive ? styles.header__navItem_active : styles.header__navItem
                                }
                                to='/'>Главная
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                onClick={closeMenu}
                                className={({isActive}) =>
                                    isActive ? styles.header__navItem_active : styles.header__navItem
                                }
                                to='/library'>Библиотека шаблонов
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                onClick={closeMenu}
                                className={({isActive}) =>
                                    isActive ? styles.header__navItem_active : styles.header__navItem
                                }
                                to='/about-us'>О проекте
                            </NavLink>
                        </li>
                    </ul>
                    <img className={styles.header__menuImage} src={imageMobile} alt=""/>
                </nav>
                <div className={styles.header__search}>
                {!isOpened ?
                    <button type='button' className={styles.search__button} onClick={handleSearchClick}></button> :
                    (windowWidth <= 980 ?
                    <div className={styles.header__search_modal}>
                        <div className={styles.header__search_relative}>
                            <button type='button' className={styles.header__modal_close}  onClick={handleCloseSearch}></button>
                            <SearchInputModif onSubmit={handleFormSubmit} onButtonClick={handleCloseSearch}/>
                            <img src={owl} alt='owl'/>
                        </div>
                    </div> :
                    <SearchInputModif  onSubmit={handleFormSubmit}/>
                    )
                }
                <a className={styles.header__phone} href='tel:+79161667500'><img src={phoneMobile} alt=""/></a>
                <ul className={styles.header__contact}>
                    <li><a className={styles.header__contact_email} href="mailto:docshablon@yandex.ru">docshablon@yandex.ru</a></li>
                    <li><a className={styles.header__contact_phone}href='tel:+79161667500'>+7 (916) 166-75-00</a></li>
                </ul>
                </div>
            </div>
            <div/>
        </header>
    );
}

export default Header;


