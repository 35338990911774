import React from 'react';
import {Outlet} from 'react-router-dom'
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import styles from './Layout.module.scss';


function Layout(props) {

    return (
       <>
           <Header />
           <div className={styles.container}>
               <Outlet />
           </div>
           <Footer />
       </>


    );
}

export default Layout;