import React from 'react';
import styles from './PhotoPopup.module.scss';

function PhotoPopup({ user, isOpen, onCloseClick }) {

  return (
    <section className={isOpen ? styles.photoPopup__opened : styles.photoPopup}>
        <div className={styles.photoPopup__container}>
            <button
            type="button"
            className={styles.photoPopup__close}
            onClick={onCloseClick}
            ></button>
            <div className={styles.photoPopup__wrapper}>   
                    <img className={styles.photoPopup__image} src={user.image} alt={user.first_name} />
                    <h3 className={styles.photoPopup__title}>{user.first_name} {user.last_name}</h3>
                    <h4 className={styles.photoPopup__caption}>{user.project_role}</h4>
                    <p className={styles.photoPopup__text}>{user.description}</p>
            </div> 
        </div>        
    </section>
        
  );
};

export default PhotoPopup;
