import { createSlice } from '@reduxjs/toolkit';

const pdfDocumentSlice = createSlice({
    name: 'pdf',
    initialState: {
        value: {}
    },
    reducers: {
        pdfGet: (state, action) => {
            state.value = action.payload; // установка введенного значения в состояние
        }, 
    }
});

export const { pdfGet } = pdfDocumentSlice.actions;

export default pdfDocumentSlice.reducer;